export const conferences = (...links) => {
  const [link_1, link_2, link_3] = links;
  return [
    {
      year: 2019,
      title:
        "Сборник трудов по итогам международной научно-практическая конференции «Social Science (Общественные науки)» (27 сентября 2019 г.)",
      link: link_1,
    },
    {
      year: 2020,
      title:
        "Сборник трудов по итогам II международной научно-практическая конференции «Social Science (Общественные науки)» (19, 20 ноября 2020 г.)",
      link: link_2,
    },
    {
      year: 2021,
      title:
        "Сборник трудов III международной научно-практической конференции «SOCIAL SCIENCE (общественные науки)",
      link: link_3,
    },
  ];
};

export const links = [
  {
    title: "Том 1: Антология памятников права народов Кавказа",
  },
  { title: "Том 2: Памятники права черкесов (Адыгов)" },
  { title: "Том 3: Памятники права черкесов (Адыгов)" },
  { title: "Том 4: Памятники права черкесов (Адыгов)" },
  { title: "Том 5: Памятники права черкесов (Адыгов)" },
  { title: "Том 6: Памятники права народов Дагестана" },
  { title: "Том 7: Памятники права донского казачества" },
  { title: "Том 8: Памятники права казачества Дона" },
  { title: "Том 9: Памятники права калмыков" },
  { title: null },
  { title: null },
  {
    title: "Том 12: Памятники права русского народа на Дону и Северном Кавказе",
  },
  { title: "Том 13: Памятники права чеченского народа" },
  { title: null },
  { title: "Том 15: Памятники права кубанского казачества" },
  { title: "Том 16: Завершение Кавказской войны" },
  { title: "Том 17: Памятники права Азербайджана" },
  { title: "Том 18: Правовые памятники Азербайджана" },
  { title: "Том 19: Памятники права Азербайджана" },
  { title: "Том 20: Истоки памятников армянского права" },
  { title: "Том 21: Памятники армянского права" },
  { title: "Том 22: Памятники права Киликийской Армении" },
  {
    title:
      "Том 23: Судебники и национальное судопроизводство в армянских колониях мира",
  },
  {
    title:
      "Том 24: Судебники и национальное судопроизводство в армянских колониях мира",
  },
  { title: "Том 25: Памятники права Грузии" },
  { title: null },
  { title: null },
  {
    title: "Том 28: Памятники истории, политического устройства и права убыхов",
  },
  { title: null },
  { title: "Том 30: Памятники права абхазов" },
  { title: null },
  { title: null },
  {
    title:
      "Том 33: Памятники права народов Северного Кавказа (постимперский период)",
  },
];

export const links2 = [
  {
    title:
      "Часть 1 История государства и права Азербайджана, Дагестана, Ингушетии, Карачая и Балкарии ",
    pdf: "Часть1.pdf",
  },
  {
    title: "Часть 2 История государства и права Чечни ",
    pdf: "Часть2.pdf",
  },
  {
    title: "Часть 3(1) История государства и права Абхазии и Черкессии.",
    pdf: "Часть3(1).pdf",
  },
  {
    title: "Часть 3(2) История государства и права Осетии и нагайцев",
    pdf: "Часть3(2).pdf",
  },
];

export const projectsInfo = [
  {
    title: "АНТОЛОГИЯ ПАМЯТНИКОВ ПРАВА НАРОДОВ КАВКАЗА",
    text: `В «Антологии памятников права народов Кавказа» публикуются материалы, содержащие информацию о государственно-правовом развитии народов Кавказа, взаимоотношениях и взаимодействии с сопредельными народами, государствами и государственными образованиями с древнейших времен до современности, отражающие влияние арабской, греческой, римско-византийской, иранской, турецкой, татаро-монгольской, российской правовых культур. 
    \nОни включают обычное право, религиозные нормы, акты органов государств, зарубежных диаспор, представляющих народы Кавказа, касающиеся государственно-правового развития этих народов, проекты способов освоения Кавказа, содержащиеся в многочисленных служебных и аналитических записках, мемуарах свидетелей исторических событий, в которых дается обоснование разных, иногда резко противоположных подходов к оценке государственно-правового опыта народов и возможностей его использования в процессе их вхождения в юридическое пространство России.
    \nМноготомное издание «Антология памятников права народов Кавказа» удостоено диплома I-й степени на VI-м Международном конкурсе научных работ по кавказоведению и южно-российскому регионоведению имени Юрия Андреевича Жданова.
    `,
    imgs: ["1.jpg"],
    data: links,
  },
  {
    title: "ИСТОРИЯ ГОСУДАРСТВА И ПРАВА НАРОДОВ КАВКАЗА",
    text: `Подпроект «История государства и права народов Кавказа» объединил в себе все труды, изданные в рамках проекта «Правовой мир Кавказа». Материалы подпроектов «Антология памятников права народов Кавказа», «Право Кавказской цивилизации», «Народная память», «Государство и право народов Кавказа» создали серьезные предпосылки для издания учебника «История государства и права народов Кавказа».
    \nУчебник «История государства и права народов Кавказа» является результатом многолетних усилий ученых, взявших на себя труд изложить предельно кратко и четко основные вехи развития представляемого ими Народа.
    \nНадеемся, что издание учебника «История государства и права народов Кавказа» послужит основанием для включения в учебные планы юридических вузов дисциплины «История государства и права народов Кавказа», благодаря чему новые поколения людей получат знания, значительно обогащающие их представление о социальной ценности исторического политико-правового опыта народов Кавказа для всего человечества.
    `,
    imgs: ["6.jpg"],
    data: links2,
  },
  {
    title: "ПРАВО КАВКАЗСКОЙ ЦИВИЛИЗАЦИИ",
    text: `«Право Кавказской цивилизации» представляет собой периодическое ежегодное издание научных работ, предметом исследования в которых являются проблемы сущности, содержания, формы, механизмов реализации права народов Кавказа, создавших и продолжающих развивать Кавказскую Цивилизацию. Главным предметом рассмотрения станут цивилизационные характеристики политико-правовых и юридических институтов, отражающих их собственный опыт и взаимодействие с иными народами и цивилизациями.
    \nИменно высокий уровень цивилизованности, реально сложившийся в кавказских сообществах, позволял их представителям, оказывавшимся в других странах, не просто приспособленчески в них существовать, а входить в элиту этих стран, активно участвовать в управлении или становиться выдающимися государственными деятелями, деятелями культуры, спорта, производства материальных благ и, разумеется, военными, оставаясь при этом верными и развивая традиционный для них образ жизнедеятельности. 
    `,
    imgs: ["2.jpg"],
    data: [],
  },
  {
    title: "НАРОДНАЯ ПАМЯТЬ",
    text: `По проекту «Народная память» предполагаются ежегодные выпуски, в которых будет содержаться информация о знаменательных событиях и памятных датах, выдающихся деятелей народов Кавказа, характеристика традиционных обычаев в их развитии, история населенных пунктов, основные достижения в области материальной и духовной культуры, исторических формах и технологиях осуществления общественной власти, особенностях политико-правового развития каждого народа Кавказа. Предполагается включение в них пословиц и поговорок, мифов и преданий как носителей духа данного народа.
    \nВ первом выпуске (2014 г.) излагается родословная аристократических фамилий Абаевых и Бугуловых, опирающаяся на хранящиеся столетиями родовые предания и архивные материалы, научно-исследовательские работы.
    `,
    imgs: ["3.jpg"],
    data: [],
  },
  {
    title:
      "СБОРНИКИ НАУЧНО-ПРАКТИЧЕСКИХ КОНФЕРЕНЦИЙ ПО ПРОБЛЕМАМ ГОСУДАРСТВЕННО-ПРАВОВОГО РАЗВИТИЯ НАРОДОВ КАВКАЗА",
    text: `В сборниках помещены тексты выступлений участников научно-практических конференций в которых анализируются исторические формы осуществления публичной власти на Кавказе: империи, монархии, республики в процессе исторического развития народов Кавказа.`,
    imgs: ["4.jpg", "5.jpg"],
    data: [],
  },
  {
    title: "ОБЫЧНОЕ ПРАВО НАРОДОВ СЕВЕРНОГО КАВКАЗА",
    text: "Данные издания обусловлены современным состоянием юридических исследований обычного права народов Северного Кавказа. В силу определенных объективных причин в данных работах отражено обычное право только отдельных народов Северного Кавказа. «Обычное право народов Северного Кавказа: итоги и перспективы исследования» являлось лишь первым этапом на пути к новым научным трудам, общей целью которых стало комплексное рассмотрение обычного права всех без исключения народов Северного Кавказа, связанных общей исторической судьбой как в прошлом, так и в настоящем.",
    imgs: ["7.jpg"],
    data: [],
  },
  {
    title: "ПАМЯТНИКИ ПРАВА НАРОДОВ КАВКАЗА",
    text: "",
    imgs: ["8.jpg"],
    data: [],
  },
];
