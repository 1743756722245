import React from "react";
import Organizers from "./Organizers";

const Info = () => {
  return (
    <section id="info">
      <div className="wrapper">
        <h1 className="title">О ПРОЕКТЕ</h1>
        <img className="project-img" src="./assets/img/project.png" alt="" />

        <article>
          <p>
            Звездное небо над головой народов Кавказа, создавших и сохранивших
            адекватный ему нравственный закон внутри человека, как свою свободу
            – нетленные основания Кавказской Цивилизации
            <br />
            <br />
            <i>
              (Дамир Шапсугов <br /> подражание И. Канту)
            </i>
          </p>
        </article>
        <br />
        <br />
        <p>
          Правовой мир, созданный народами Кавказа, - огромный и мощный пласт
          человеческой культуры, который нам предстоит осмыслить. Как отмечал Г.
          Гегель «Только в Кавказской расе дух приходит к абсолютному единству с
          самим собой, только здесь дух вступает в полную противоположность с
          условиями природного существования, постигая себя в своей абсолютной
          самостоятельности, вырываясь из постоянного колебания туда и сюда, от
          одной крайности к другой, достигает самоопределения, саморазвития и
          тем самым осуществляет всемирную историю ... Прогресс осуществляется
          только благодаря кавказской расе».
        </p>
        <p>
          Есть все основания рассматривать Кавказ как регион, в котором
          происходило и происходит взаимодействие Востока и Запада, их
          культурно-правовой синтез. Все это обусловливает потребность в
          рассмотрении правового мира Кавказа как научной проблемы и как
          проблемы практической. Как научная проблема эта тема представляет
          огромный интерес в силу существования богатейшего правового опыта
          народов Кавказа, во многом еще просто неизвестного подавляющему
          большинству современников и малодоступного даже ученым исследователям,
          среди которых историки права пока едва заметны. Этот опыт бесспорно
          представляет огромный интерес и для теории права, и нет сомнения в
          том, что на его оригинальном содержании возможно разрабатывать не
          только новую теорию права, но и новый тип правопонимания -
          правопонимание народов Кавказа.
        </p>
        <p>
          Поначалу проблемы данной тематики обсуждались на страницах научного
          журнала «Северо-Кавказский юридический вестник», входящего в Перечень
          рецензируемых научных изданий ВАК при Минобрнауки РФ. Затем в практику
          вошло ежегодное проведение научно-практических конференций по
          проблемам государственно-правового развития народов Кавказа, издание
          сборников материалов конференций, научных трудов известных ученых.
        </p>
        <p>
          2009 год был ознаменован рождением составляющих головного проекта
          «Правовой мир Кавказа». Первым стал подпроект «Антология памятников
          права народов Кавказа», рассмотренный и принятый на заседании Ученого
          совета Института государства и права РАН 20 мая 2009 года.
        </p>
        <p>
          Работа над «Антологией памятников права народов Кавказа» дала толчок к
          зарождению новых подпроектов. Сейчас параллельно с «Антологией
          памятников права народа Кавказа» идет работа над проектами «Учебник
          истории государства и права народов Кавказа», «Толковый словарь
          национальных юридических терминов народов Кавказа», «Народная память»,
          «Право Кавказской цивилизации», которые были единодушно поддержаны
          участниками Международной научно-практической конференции «Проблемы
          методологии исследования государственно-правового развития народов
          Кавказа», прошедшей в Нальчике (КБР) в мае 2013 г. в рамках общего
          проекта «Правовой мир Кавказа», сделавшего проведение подобных
          конференций традиционно ежегодными.
        </p>
        <p>
          Проект вышел далеко за рамки внутриинститутского издания. Над ним
          работают ученые практически всех государственных и территориальных
          образований Северного Кавказа. Увлек проект и коллег из государств
          Южного Кавказа - Азербайджана, Армении, Грузии, Абхазии.{" "}
        </p>
        <p>
          В разработке проекта активно участвуют такие известные ученые – юристы
          и историки, как:
        </p>
        <p>
          А. Абазов, П. Абайханова, Е. Авакян, Р. Авакян, А. Авидзба, В.
          Авидзба, С. Алиева, К. Ажахов, А. Ажахов, З. Азимов, Т. Айтберов, А.
          Актбиев, И. Аксенов, Г. Давиташвили, Х. Думанов, К. Дзамихов, М.
          Дышеков, Е. Зинков, Ш. Исаев, М. Исмаилов, Х. Исмайлов, Ф. Камкия, Н.
          Касландзия, Е. Команджаев, К. Краковский, П. Кузьминов, М. Кишмахов,
          А. Ларионов, И. Магомедсултанов, А. Мануйлов, А. Маремкулов, М.
          Меликова, Г. Небратенко, Ф. Озова, Д. Сайдумов, Н. Сангуция, Л.
          Свечникова, В. Сергеев, С. Салакая, М. Супатаев, Б. Схатум, Л.
          Сюкияйнен, А. Цалиев, Т. Шатковская, А. Урушадзе, М. Шапсугова, Д.
          Эгнаташвили, З. Яхтанигов и многие другие.
        </p>
        <p>
          Проект замечен, одобрен и рекомендован к дальнейшей разработке
          Комитетами по региональной политике и местному самоуправлению
          Государственной Думы Федерального Собрания Российской Федерации (19
          июня 2013 г. № 319-22/95).
        </p>
        <br />
        <Organizers />
        <br />
      </div>
    </section>
  );
};

export default Info;
