import React from "react";

const organizers = [
  {
    title: "",
  },
  {
    title:
      "Центр правовых исследований Институт государства и права Российской Академии наук Южный филиал\nИнститут государства и права Российской Академии наук Южный филиал",
  },
  {
    title: "Российская Академия юридических наук\nСеверо-Кавказское отделение",
  },
  {
    title: "Бакинский государственный университет",
  },
  { title: "Ереванский университет «МАНЦ»" },
  { title: "Дагестанский государственный университет" },
  { title: "Калмыцкий государственный университет" },
  { title: "Кабардино-Балкарский государственный университет" },
  { title: "Чеченский государственный университет" },
  { title: "Карачаево-Черкесский государственный университет" },
  { title: "Ингушский государственный университет" },
  { title: "Тбилисский государственный университет" },
  { title: "Ассоциация Юристов Кавказа" },
  { title: "Кабардино-Балкарский институт гуманитарных исследований" },
  { title: "Абхазский институт гуманитарных исследований" },
  {
    title:
      "Комплексный научно-исследовательский институт Им. Х.И. Ибрагимова Российской Академии Наук",
  },
  { title: "Адыгская Международная академия наук" },
];

const Organizers = () => {
  return (
    <>
      <div className="organizers" id="organizer">
        <h1>Руководитель проекта</h1>
        <div className="organizer-img"></div>
        <h3 className="name">Шапсугов Дамир Юсуфович</h3>
        <p>
          доктор юридических наук, профессор, заслуженный юрист РФ, директор
          Центра правовых исследований ЮРИУ РАНХиГС, главный редактор журнала
          «Северо-Кавказский юридический вестник».
        </p>
        <p>
          Шапсуговым Д.Ю. разработана авторская концепция народовластия,
          государственной и местной власти, системы прав человека, проблемы
          правового и юридического мышления, научной критики, теории права и
          государства, создана научная школа по изучению обычного права народов
          Юга России, школа правового мышления.
        </p>
        <p>
          Под его руководством подготовлено и защищено 40 кандидатских и
          докторских диссертаций.
        </p>
        <ul>
          <p>
            В «арсенале» ученого более 200 научных трудов: монографий, учебных
            пособий, научных статей и других изданий научной, учебной и
            учебно-методической литературы, многие из которых уже давно стали
            настольными книгами для молодого поколения юристов, признаны как
            новое научное значение. Среди них:
          </p>
          <li>
            «Теория государства и права» – в двух частях. – 1ч. 1993г., 2ч.
            1999г.-г.Ростов-на-Дону;
          </li>
          <li>
            «Государственная и местная власть на Дону в 1917г.»-Соавторы: проф.
            В.Н.Сергеев, доц. Н.В. Звездова – 2000г.;
          </li>
          <li>
            «Местная власть в России и Германии» – г.Ростов-на-Дону,1994г.;
          </li>
          <li>
            «Проблемы теории и истории власти, права и государства»–Москва –
            2003г.;
          </li>
          <li>«Рассудок-Разум-Право»</li>
          <li>«Проблема свободного мышления в юридической науке» – 2020г.;</li>
          <li>
            «Парламентская деятельность российского казачества (1906-1917гг.) –
            соавтор проф. В.Н. Сергеев.
          </li>
          <li>
            «Теория государства и права» – Учебник для вузов. Соавторы проф.
            В.Я. Любашиц, проф. А.Ю. Мордовцев,{" "}
          </li>
          <li>
            «Народовластие: Опыт политико-правового исследования»: Акад.
            обществ. наук. - Москва, 1991.
          </li>
        </ul>
        <p>
          По инициативе Шапсугова Д.Ю. созданы Ростовский юридический институт
          СКАГС, в котором была развернута полная вузовская инфраструктура от
          профильных юридических классов в средней школе, колледжа, системы
          высшего образования до аспирантуры, докторантуры и диссертационного
          Совета; Северо-Кавказское отделение Российской академии юридических
          наук; Южный филиал института государства и права РАН; Ассоциация
          юристов Кавказа; научно-публицистическая юридическая газета Юга России
          «Мир права», оказавшие позитивное влияние на разработку проекта
          «Правовой мир Кавказа».
        </p>
        <p>
          Под редакцией Шапсугова Д.Ю. в с 1997 года издается научный журнал
          «Северо-Кавказский юридический вестник». Журнал входит в Перечень
          рецензируемых научных изданий, в которых должны быть опубликованы
          основные научные результаты диссертаций на соискание ученой степени
          кандидата наук, на соискание ученой степени доктора наук ВАК при
          Министерстве науки и высшего образования Российской Федерации.
        </p>
        <p>
          С 2003 по 2007 г.г. Шапсугов Д.Ю являлся членом экспертного Совета ВАК
          Министерства образования Российской Федерации.
        </p>
        <p>
          В декабре 2012 г. на VI международном конкурсе научных работ по
          кавказоведению и южнороссийскому регионоведению имени Юрия Андреевича
          Жданова Шапсугову Д.Ю. был вручен диплом I степени за издание новых
          10-ти томов «Антологии памятников права народов Кавказа».
        </p>
      </div>
      <div className="so-organizers" id="so-organizers">
        <h1>
          Участники проекта <br />(НА РАЗНЫХ ЭТАПАХ ЕГО РАЗРАБОТКИ)
        </h1>
        <div className="so-organizers__container">
          {organizers.map(
            (item, i) =>
              i !== 0 && (
                <div key={i} className="so-organizers__item">
                  <img src={`./assets/img/so-organizers/${i + 1}.png`} alt="" />
                  <p>{item.title}</p>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default Organizers;
