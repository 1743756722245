import React from "react";

const Contacts = () => {
  return (
    <section id="contacts">
      <div className="wrapper">
        <ul>
          Сегодня в стадии разработки находятся новые составляющие головного
          проекта, такие как:
          <li>
            «Толковый словарь национальных юридических терминов народов Кавказа»
            (На современном этапе, некоторые части помещены в отдельных изданиях
            проекта);
          </li>
          <li>
            «Известные ученые исследователи политико-правовой истории народов
            Кавказа»;
          </li>
          <li>«Выдающиеся государственные деятели народов Кавказа»;</li>
          <li>
            Ученые исследователи политико-правовой истории народов Кавказа»;
          </li>
          <li>
            «Выдающиеся политико-правовые события в жизни народов Кавказа»;
          </li>
          <li>«Фонд юридических пословиц и поговорок народов Кавказа»;</li>
          <li>«Общекавказские государственные правовые образования»</li>
          <img src="./assets/img/contacts.png" alt="" />
        </ul>
        <h1>Наши контакты</h1>
        <p>
          <b className="extra-bold">
            Приглашаем к сотрудничеству всех, кто способен внести вклад в
            реализацию данного проекта
          </b>
        </p>
        <br />
        <p>
          <b>E-mail:</b> <span>shapsugov@yandex.ru</span> <br /> <b>тел.:</b>{" "}
          8(863)240-97-17; 8(863)244-12-95
          <br />
          <br />
          <br />
        </p>
      </div>
    </section>
  );
};
export default Contacts;
